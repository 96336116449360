import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';

import axiosApi, { baseURL } from '~src/domains/core/axiosApi';
import generateSessionNonce, {
	generateSessionHash,
	getUniqueID,
} from '~src/domains/core/lib/generateSessionHash';
import Logger from '~src/domains/core/lib/Logger';
import { errorToString } from '~src/utils/errorUtil';

/**
 * Setup API type helpers
 */

const ResponseSuccess = <C extends t.Props>(rest: C) =>
	t.type({
		isSuccess: t.literal(true),
		...rest,
	});

const ResponseError = t.type({
	isSuccess: t.literal(false),
	errorMessage: t.string,
});

const ApiResponse = <C extends t.Props>(rest: C) =>
	t.union([ResponseError, ResponseSuccess(rest)]);

const ApiResponseData = <C extends t.Mixed>(value: C) =>
	ApiResponse({
		data: value,
	});
type ApiResponseData<T> =
	| t.TypeOf<typeof ResponseError>
	| {
			isSuccess: true;
			data: T;
	  };

// This function takes an axios response, handles errors and returns the expected type.
function handleApiData<T>(value: unknown, type: t.Type<ApiResponseData<T>>): T {
	const validation = type.decode(value);
	if (isLeft(validation)) {
		const report = PathReporter.report(validation).join('\n');
		throw new Error(`Invalid response:\n${report}`);
	}

	const resolved = validation.right;

	if (ResponseError.is(resolved)) {
		throw new Error(resolved.errorMessage);
	}

	return resolved.data;
}

const ResponseSessionValid = t.type({
	valid: t.literal(true),
});
const ResponseSessionInvalid = t.type({
	valid: t.literal(false),
});

const HeaderSessionType = t.type({
	'x-session': t.string,
});

const ResponseSessionType = t.type({
	session: t.union([ResponseSessionValid, ResponseSessionInvalid]),
});
type ResponseSessionType = t.TypeOf<typeof ResponseSessionType>;

const pageSessionId = getUniqueID();

const apiSession = {
	get sessionData() {
		const localData = localStorage.getItem('session');
		if (localData == null) return;
		const parts = localData.split('.');
		if (parts.length != 3) return;
		const session = parts[0] as string;
		const t = parts[1] as string;
		const pt = parts[2] as string;
		return {
			session,
			t,
			pt,
		};
	},

	set sessionData(
		data: undefined | { session: string; t: string; pt: string },
	) {
		if (data == null) {
			localStorage.removeItem('session');
			return;
		}
		localStorage.setItem('session', `${data.session}.${data.t}.${data.pt}`);
	},

	createSessionPromise: null as null | Promise<{
		session: string;
		t: string;
		pt: string;
	}>,

	async createSession() {
		if (this.createSessionPromise != null) {
			return this.createSessionPromise;
		}
		return (this.createSessionPromise = (async () => {
			const response = await createSession();
			this.createSessionPromise = null;
			return {
				...response,
				pt: response.session,
			};
		})());
	},

	async getSession() {
		if (this.sessionData == null) {
			this.sessionData = await this.createSession();
		}
		return this.sessionData;
	},

	wrap<Args extends unknown[], Ret>(
		fn: (...args: Args) => Ret,
	): (...args: Args) => Promise<Awaited<Ret>> {
		return async (...args: Args): Promise<Awaited<Ret>> => {
			const { session, t, pt } = await this.getSession();
			const nonce = generateSessionNonce(pt, t);
			const requestIntercept = axiosApi.interceptors.request.use((config) => {
				const hash = generateSessionHash(config.data ?? {}, nonce);
				config.data = {
					...config.data,
					session: session,
					t: `${nonce}.${hash}`,
				};
				return config;
			});
			const responseIntercept = axiosApi.interceptors.response.use(
				(response) => {
					const { headers, data } = response;
					if (HeaderSessionType.is(headers)) {
						const { 'x-session': responseSession } = headers;
						this.sessionData = {
							session: session,
							pt: nonce,
							t: responseSession,
						};
					} else if (
						ResponseSessionType.is(data) &&
						ResponseSessionInvalid.is(data.session)
					) {
						this.sessionData = undefined;
					}

					return response;
				},
			);
			try {
				return await fn(...args);
			} finally {
				axiosApi.interceptors.request.eject(requestIntercept);
				axiosApi.interceptors.response.eject(responseIntercept);
			}
		};
	},
};

export const getSession = () => apiSession.getSession();

function decorateApiFunction<
	FN extends (...args: never[]) => Promise<unknown>,
	T,
>(
	name: string,
	type: t.Type<ApiResponseData<T>>,
	fn: FN,
	isSessionRequired = false,
): (...args: Parameters<FN>) => Promise<T> {
	const fnWrapped = isSessionRequired ? apiSession.wrap(fn) : fn;
	return async (...args) => {
		try {
			const response = await fnWrapped(...args);
			return handleApiData(response, type);
		} catch (error) {
			Logger.get().error(
				`api.${name}`,
				`Got an error: ${errorToString(error)}`,
			);
			throw error;
		}
	};
}

/**
 * Setup root API
 */

const api = axiosApi;

/**
 * Create Session
 */

const CreateSessionResponse = ApiResponseData(
	t.type({
		session: t.string,
		t: t.string,
	}),
);

const createSession = decorateApiFunction(
	'session',
	CreateSessionResponse,
	async () => {
		const { data } = await api.get('session');

		return data;
	},
);

/**
 * Leaderboard Entry
 */

const LeaderboardEntryResponse = ApiResponseData(
	t.type({
		accepted: t.boolean,
	}),
);

export const leaderboardEntry = decorateApiFunction(
	'leaderboardEntry',
	LeaderboardEntryResponse,
	async (
		firstName: string,
		lastInitial: string,
		nickName: string | null,
		gameId: number,
		score: number,
	) => {
		const { data } = await api.post('leaderboardEntry', {
			firstName,
			lastInitial,
			nickName,
			gameId,
			score,
		});

		return data;
	},
	true,
);

/**
 * Leaderboard
 */

const GetLeaderboardResponse = ApiResponseData(
	t.array(
		t.type({
			firstName: t.string,
			lastInitial: t.string,
			nickName: t.union([t.null, t.string]),
			score: t.number,
			datetime: t.number,
		}),
	),
);

export const getLeaderboard = decorateApiFunction(
	'getLeaderboard',
	GetLeaderboardResponse,
	async (gameId: number) => {
		const { data } = await api.get('leaderboard', {
			params: { gameId },
		});

		return data;
	},
);

/**
 * Registration
 */
const RegistrantEntryResponse = ApiResponseData(
	t.intersection([
		t.type({
			accepted: t.boolean,
		}),
		t.partial({
			message: t.string,
		}),
	]),
);

export const registrantEntry = decorateApiFunction(
	'registrantEntry',
	RegistrantEntryResponse,
	async (
		firstName: string,
		lastName: string,
		emailAddress: string,
		zip: string,
		gameId: number,
	) => {
		const { data } = await api.post('registrantEntry', {
			firstName,
			lastName,
			emailAddress,
			zip,
			gameId,
		});

		return data;
	},
	true,
);

export function analyticsEvent(name: string, value?: string | number) {
	const session = apiSession.sessionData?.session;
	const formData = new FormData();
	formData.set('e', name);
	if (value != null) {
		formData.set('v', `${value}`);
	}
	formData.set('ps', pageSessionId);
	if (session != null) {
		formData.set('s', session);
	}

	if (navigator.sendBeacon != null) {
		if (navigator.sendBeacon(`${baseURL}/a`, formData)) {
			return;
		}
	}
	return api.post('a', formData);
}

const dimensionsCache = {} as Record<string, string>;

export function analyticsDimension(name: string, value: string) {
	if (dimensionsCache[name] === value) {
		return;
	}
	const session = apiSession.sessionData?.session;
	const formData = new FormData();
	formData.set('d', name);
	formData.set('v', `${value}`);
	formData.set('ps', pageSessionId);
	if (session != null) {
		formData.set('s', session);
	}

	api.post('a', formData);

	dimensionsCache[name] = value;
}
