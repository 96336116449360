@use 'src/assets/config.scss';

.root {
	border: none;
	background-color: transparent;
	font-size: inherit;
	color: inherit;

	&.primary,
	&.secondary {
		font-family: 'Xfinity Brown TT', sans-serif;
		font-size: 1.125rem;
		text-align: center;
		color: config.$white;
	}

	&.primary {
		background-color: config.$white;
		color: config.$black;
		height: 62px;
		padding: 0 3.3rem 5px;
		border-radius: 8px;
		font-size: 18px;
		font-weight: bold;
		line-height: 62px;
		//letter-spacing: 1px;		
	}

	&.secondary {
		background-color: transparent;

		&.outline {
			border: solid 2px config.$white;
			height: 62px;
			padding: 0 2.5rem;
			border-radius: 8px;
			line-height: 56px;
			//letter-spacing: 1px;
			font-weight: bold;
		}

		&.underline {
			padding-bottom: 5px;
			border-bottom: solid 2px;
		}
	}
}
